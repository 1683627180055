import React from 'react';

const Hello = () => {
  return(
    <div className="row">
      <div className="col-12">
        this is a component
      </div>
    </div>
  )
}

export default Hello;