import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"

import "../stylesheets/application.scss";

import "bootstrap";
// import FontAwesome  from "@fortawesome/fontawesome-free/js/all";

// import I18n from 'i18n-js/index.js.erb'

require("packs/components");

const _ = require('lodash');

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

// Turbolinks.controller.notifyApplicationAfterPageLoad()

const images = require.context("../images", true)

window.bootstrap = require('bootstrap');

window.jQuery = $;
window.$ = $;
global.$ = $;

// FontAwesomeConfig = {
//   searchPseudoElements: true,
//   autoReplaceSvg: false
// }

global.Bloodhound = require('corejs-typeahead/dist/bloodhound.js');
require('corejs-typeahead/dist/typeahead.jquery.js');

$(function(){
  $('[data-toggle="tooltip"]').tooltip({ html: true })
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});


document.addEventListener("turbolinks:load", function() {
});


// Fix turbolinks anchor links reload
document.addEventListener('turbolinks:click', function (event) {
  var anchorElement = event.target
  var isSamePageAnchor = (
    anchorElement.hash &&
    anchorElement.origin === window.location.origin &&
    anchorElement.pathname === window.location.pathname
  )
  if (isSamePageAnchor) {
    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(
      event.data.url,
      Turbolinks.uuid()
    )
    event.preventDefault()
  }
})